import path from 'path';
import { graphql } from 'gatsby';
import { howItWorks as pageMeta } from '../data/page_meta.yml';
import PagePost from '../models/PagePost';
import PropTypes from 'prop-types';
import React from 'react';
import BackgroundImage from 'gatsby-background-image';
import { siteMeta } from '../data/site_meta.yml';
import PageWrapper from '../components/DefaultPageWrapper';
import {
    SEO,
} from '@brandedholdings/react-components';

class HowItWorks extends React.Component {
    getChildContext() {
        return { siteMeta };
    }

    render() {
        const page = this.props;
        pageMeta.link = page.location.pathname;
        const post = new PagePost(pageMeta, siteMeta);

        const pageImages = {};
        page.data.pageImages.edges.forEach(({ node }) => pageImages[path.basename(node.absolutePath).split('.')[0]] = node);

        return (
            <PageWrapper>
                <SEO post={post} />
                <BackgroundImage
                    className="background__gatsby background background__graphic"
                    fluid={pageImages.hero.image.fluid}
                >
                    <div className="layout">
                        <div className="layout-content center">
                            <h1>How it Works</h1>
                        </div>
                    </div>
                </BackgroundImage>
                <div className="background background__white">
                    <div className="layout">
                        <div className="layout-content">
                            <h3>About {siteMeta.nameShort}</h3>
                            <p>
                                {siteMeta.nameShort} is an online solution to help you find lenders who can provide financial assistance fast. The lenders who are part of CashHappen’s marketplace are reputable and trustworthy, and the process of submitting your information is quick, secure, and hassle-free. 
                            </p>
                            <hr />
                            <h3>Submit Your Request in Just a Few Minutes</h3>
                            <p>
                                Once your information has been submitted, we will send it to our network of lenders who will then review it instantly. If a lender chooses to work with you, you will then be redirected to their website and presented with an offer. There, you will have an opportunity to review the exact terms of the loan, and if you accept, your money could be transferred directly to your account in as little as 24 hours*
                            </p>

                            <hr />
                            <h3>Minimum Qualifications</h3>
                            <ul className="list">
                                <li>Must be 18+ years old and a U.S. citizen</li>
                                <li>Valid checking account</li>
                                <li>Recurring source of income</li>
                                <li>Social Security Number</li>
                                <li>Current address &amp; phone number</li>
                            </ul>
                            <p>
                                This information is required to submit our online form, but does not provide a guarantee you will find a lender.
                            </p>
                        </div>
                    </div>
                </div>
            </PageWrapper>
        );
    }
};

HowItWorks.childContextTypes = {
    siteMeta: PropTypes.object
};

export default HowItWorks;

export const query = graphql`
    {
        pageImages: allFile(filter: {absolutePath: {regex: "^static/assets/images/covers/"}}) {
            edges {
                node {
                    id
                    absolutePath
                    image: childImageSharp {
                        fluid(
                            quality: 90, 
                            maxWidth: 2000,
                            traceSVG: { background: "#0ba7e2", color: "#0ba7e2" }
                        ) {
                            # base64
                            tracedSVG
                            aspectRatio
                            src
                            srcSet
                            srcWebp
                            srcSetWebp
                            sizes
                            originalImg
                            originalName
                            presentationWidth
                            presentationHeight
                        }
                    }
                }
            }
        }
    }
`;


